<template>
  <div class="tag-box">
    <el-tag
      :key="tag"
      v-for="tag in list"
      closable
      :disable-transitions="false"
      @close="handleClose(tag)"
    >
      {{ tag }}
    </el-tag>
    <el-input
      class="input-new-tag"
      v-if="inputVisible"
      v-model="inputValue"
      ref="saveTagInput"
      size="small"
      @keyup.enter.native="handleInputConfirm"
      @blur="handleInputConfirm"
    >
    </el-input>
    <el-button v-else class="button-new-tag" size="small" @click="showInput"
      >+ 添加</el-button
    >
  </div>
</template>

<script>
export default {
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      inputVisible: false,
      inputValue: '',
    }
  },
  computed: {
    list: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('change', val)
      },
    },
  },
  methods: {
    handleClose(tag) {
      this.$emit('delete', tag)
      this.list.splice(this.list.indexOf(tag), 1)
    },
    showInput() {
      this.inputVisible = true
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },

    handleInputConfirm() {
      let inputValue = this.inputValue
      if (this.list.some((t) => t === inputValue)) {
        this.$message.error('标签已存在')
        return
      }
      if (inputValue) {
        this.$emit('add', inputValue)
        this.list.push(inputValue)
      }
      this.inputVisible = false
      this.inputValue = ''
    },
  },
}
</script>

<style lang="scss" scoped>
.tag-box {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 300px;
  .button-new-tag {
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    margin-top: -4px;
    width: 90px;
    vertical-align: bottom;
  }
}
</style>
