<template>
  <div class="page" v-loading="loading">
    <el-form
      class="save-content"
      label-width="100px"
      :model="form"
      :rules="rules"
      ref="saveForm"
    >
      <el-form-item label="岗位名称：" prop="name">
        <el-input v-model="form.name" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="所属企业：" prop="company_id">
        <el-select
          v-model="form.company_id"
          @change="changeCompanyId"
          placeholder="请选择"
        >
          <el-option
            v-for="item in companyArr"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="form.company_category"
        label="岗位地址："
        prop="address_id"
        v-loading="addressLoading"
      >
        <el-select
          v-model="form.address_id"
          @change="changeAddress"
          placeholder="请选择"
        >
          <el-option
            v-for="(item, i) in addressArr"
            :key="i"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-button
          type="primary"
          :style="{
            marginLeft: '12px',
          }"
          @click="addressSelector.show = true"
          >添加地址</el-button
        >
      </el-form-item>
      <el-form-item label="截止时间：" prop="close_time">
        <el-date-picker
          time-arrow-control
          v-model="form.close_time"
          type="datetime"
          value-format="timestamp"
          format="yyyy-MM-dd HH:mm"
          placeholder="选择日期时间"
        >
        </el-date-picker>
      </el-form-item>
      <!-- 没有所属地区了 -->
      <!-- <el-form-item label="所属地区：" prop="region">
        <el-cascader
          :value="form.region ? form.region.split(',') : ''"
          :options="areaData"
          :props="{
            expandTrigger: 'hover',
            value: 'label',
          }"
          collapse-tags
          clearable
          filterable
          @change="(e) => (form.region = e.join(','))"
        ></el-cascader>
      </el-form-item> -->
      <el-form-item label="要求学历：" prop="education">
        <el-select v-model="form.education" placeholder="请选择">
          <el-option
            v-for="item in education"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="岗位类型：" prop="category_id">
        <el-select v-model="form.category_id" placeholder="请选择岗位类型 ">
          <el-option
            v-for="item in categoryIdArr"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <!--      <el-form-item label="岗位行业：" prop="company_category">-->
      <!--        <el-cascader-->
      <!--          placeholder="请选择，可搜索"-->
      <!--          :value="form.company_category.split(',')"-->
      <!--          :options="companyCategoryArr"-->
      <!--          @change="(val) => (form.company_category = val.join(','))"-->
      <!--          filterable-->
      <!--          :props="{-->
      <!--            checkStrictly: true,-->
      <!--            label: 'name',-->
      <!--            value: 'name',-->
      <!--            children: 'children',-->
      <!--            expandTrigger: 'hover',-->
      <!--          }"-->
      <!--        ></el-cascader>-->
      <!--      </el-form-item>-->
      <el-form-item label="岗位分类：" prop="types">
        <div class="flex-col" style="display: inline-flex">
          <el-cascader
            :value="form.types.map((t) => t.split(','))"
            :options="careerTypesArr"
            :props="{
              expandTrigger: 'hover',
              value: 'name',
              label: 'name',
              multiple: true,
              checkStrictly: true,
            }"
            clearable
            collapse-tags
            filterable
            @change="(e) => (form.types = e.map((t) => t.join(',')))"
            popper-class="employ-types-popper"
          ></el-cascader>
          <!-- 用来显示全部选中的分类，方便总览及删除 -->
          <!--          <el-cascader v-if="form.types.length>1"-->
          <!--            class="category"-->
          <!--            :value="form.types.map((t) => t.split(','))"-->
          <!--            :options="careerTypesArr"-->
          <!--            :props="{-->
          <!--              expandTrigger: 'hover',-->
          <!--              value: 'name',-->
          <!--              label: 'name',-->
          <!--              multiple: true,-->
          <!--              checkStrictly: true,-->
          <!--            }"-->
          <!--            @change="(e) => (form.types = e.map((t) => t.join(',')))"-->
          <!--            popper-class="popper-class"-->
          <!--          ></el-cascader>-->
        </div>
      </el-form-item>
      <el-form-item label="薪资设置：" required>
        <el-radio-group v-model="wagesRadio">
          <el-radio :label="0">面议</el-radio>
          <el-radio :label="1">区间</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-if="wagesRadio"
        style="width: 450px"
        label="岗位薪资："
        required
      >
        <el-col :span="11">
          <el-form-item prop="min_wages">
            <el-input-number
              style="width: 130px"
              v-model="form.min_wages"
              controls-position="right"
              :min="1"
            ></el-input-number
            >&nbsp;&nbsp;K
          </el-form-item>
        </el-col>
        <el-col class="line" :span="2">-</el-col>
        <el-col :span="11">
          <el-form-item prop="max_wages">
            <el-input-number
              style="width: 130px"
              v-model="form.max_wages"
              controls-position="right"
              :min="1"
            ></el-input-number
            >&nbsp;&nbsp;K
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item label="经验设置：" required>
        <el-radio-group v-model="expRadio">
          <el-radio :label="0">无要求</el-radio>
          <el-radio :label="1">区间</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="expRadio" style="width: 450px" label="经验要求：" required>
        <el-col :span="11">
          <el-form-item prop="min_exp">
            <el-input-number
              style="width: 130px"
              v-model="form.min_exp"
              controls-position="right"
              :min="0"
            ></el-input-number
            >&nbsp;&nbsp;年
          </el-form-item>
        </el-col>
        <el-col class="line" :span="2">-</el-col>
        <el-col :span="11">
          <el-form-item prop="max_exp">
            <el-input-number
              style="width: 130px"
              v-model="form.max_exp"
              controls-position="right"
              :min="0"
            ></el-input-number
            >&nbsp;&nbsp;年
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item label="投递模式：" prop="deliver_mode">
        <el-radio-group v-model="form.deliver_mode">
          <el-radio :label="0">简历代投</el-radio>
          <el-radio :label="1">自行网申</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="form.deliver_mode === 1" label="投递链接：" prop="deliver_url">
        <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4}" placeholder="请输入" v-model="form.deliver_url" />
      </el-form-item>
      <el-form-item label="岗位标签：">
        <Tag
          :value="form.tags.map((t) => t.name)"
          @add="addTag"
          @delete="
            (tag) => {
              form.tags = form.tags.filter((t) => t.name !== tag)
            }
          "
        />
      </el-form-item>
      <el-form-item label="岗位描述：" prop="content">
        <tinymce
          id="tinymce-editor"
          ref="editor"
          @choose="handleToolBarClick"
          v-model="form.details"
          show-article-import
          style="max-width: 540px"
        />
      </el-form-item>
    </el-form>
    <FixedActionBar :z-index="2">
      <el-button @click="cancel">取消</el-button>
      <el-button type="primary" @click="saveConfig">保存</el-button>
    </FixedActionBar>
    <!-- 富文本插入图片 -->
    <media-selector v-model="showImageList" multiple @select="updateImages" />
    <media-selector
      v-model="showVideoList"
      type="video"
      multiple
      @select="updateVideos"
    />
    <media-selector
      v-model="showAudioList"
      type="audio"
      multiple
      @select="updateAudios"
    />
    <!-- 文章导入 -->
    <import-article v-model="isShowImportDialog" @confirm="getImportDetail" />
    <!-- 导入HTML -->
    <import-html-code v-model="isShowHtmlInput" />
    <AddressSelector
      v-model="addressSelector.show"
      @change="handleChangeAddress"
      :detailForm="addressSelector.form"
    />
  </div>
</template>

<script>
import FixedActionBar from '@/base/layout/FixedActionBar'
import Tag from '@/modules/employ/components/employ-config/Tag.vue'
// 接口
import {
  getCompanyDetail,
  getCareerDetail,
  saveCareer,
  getCategories,
  saveTag,
  careerTypes,
  saveAddress,
} from '@/modules/employ/api/employ-career-list'

import { getCompanyAll } from '@/modules/employ/api/employ-company-list'
import { categoryList } from '@/modules/employ/api/employ-career-category-list'
import { education } from '@/modules/employ/data/index'
import tinymceMixin from '@/modules/employ/mixin/tinymce-mixin'
import { getAddressJson } from '@/modules/employ/api/common'
import AddressSelector from '@/modules/common/components/AddressSelector.vue'

export default {
  mixins: [tinymceMixin],
  components: { FixedActionBar, Tag, AddressSelector },
  data() {
    const max_wages = (rule, value, callback) => {
      if (this.form.min_wages > this.form.max_wages) {
        callback(new Error('最高薪资不能地于最低薪资'))
      } else {
        callback()
      }
    }
    const max_exp = (rule, value, callback) => {
      if (this.form.min_exp > this.form.max_exp) {
        callback(new Error('不能地于最低经验要求'))
      } else {
        callback()
      }
    }
    return {
      addressLoading: false,
      addressSelector: {
        show: false,
        index: -1,
        form: {
          lat: '',
          lng: '',
          address: '',
          address_name: '',
        },
      },
      // 地区
      areaData: null,
      // 学历
      education,
      // 岗位类型
      categoryIdArr: [],
      // 所属企业
      companyArr: [],
      // 岗位行业
      // companyCategoryArr: [],
      // 所属分类
      careerTypesArr: [],
      form: {
        id: 0,
        name: '',
        close_time: '',
        region: '',
        education: '',
        company_id: '',
        category_id: '',
        details: '',
        company_category: '',
        types: [],
        tags: [],
        min_wages: 0,
        max_wages: 0,
        min_exp: 0,
        max_exp: 0,
        address_id: '',
        deliver_mode: 0, //(0:简历代投，1:自行网申)
        deliver_url: ''
      },
      loading: false,
      // 校验规则
      rules: {
        name: [
          {
            required: true,
            message: '请输入企业名称',
            trigger: 'blur',
          },
        ],
        close_time: [
          {
            type: 'date',
            required: true,
            message: '请选择日期',
            trigger: 'change',
          },
        ],
        region: [
          {
            required: true,
            message: '请选择所属地区',
            trigger: 'change',
          },
        ],
        education: [
          {
            required: true,
            message: '请选择要求学历',
            trigger: 'change',
          },
        ],
        company_id: [
          {
            required: true,
            message: '请选择所属企业',
            trigger: 'change',
          },
        ],
        address_id: [
          {
            required: true,
            message: '请选择岗位地址',
            trigger: 'change',
          },
        ],
        category_id: [
          {
            required: true,
            message: '请选择岗位类型',
            trigger: 'change',
          },
        ],
        // company_category: [
        //   {
        //     required: true,
        //     message: '请选择岗位行业',
        //     trigger: 'blur',
        //   },
        // ],
        types: [
          {
            required: true,
            message: '请选择所属分类',
            trigger: 'change',
          },
        ],
        details: [
          {
            required: true,
            message: '请输入岗位描述',
            trigger: 'blur',
          },
        ],
      
        min_wages: [
          {
            required: true,
            message: '请输入最低经验要求',
            trigger: 'blur',
          },
        ],
        max_wages: [
          {
            validator: max_wages,
            required: true,
            trigger: 'blur',
          },
        ],
        min_exp: [
          {
            required: true,
            message: '请输入最低经验要求',
            trigger: 'blur',
          },
        ],
        max_exp: [
          {
            validator: max_exp,
            required: true,
            trigger: 'blur',
          },
        ],
        deliver_url: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入投递链接'
          }
        ]
      },
      // 地址选择
      addressArr: [],
    }
  },
  methods: {
    // 新增地址
    handleChangeAddress(val) {
      val = {
        company_id: this.form.company_id,
        name: val.address_name,
        address: val.address,
        lat: val.lat,
        lng: val.lng,
      }
      this.addressLoading = true
      saveAddress(val)
        .then((res) => {
          console.log(res)

          getCompanyDetail({ id: this.form.company_id })
            .then((res) => {
              console.log(res)
              this.$message.success('添加地址成功')

              this.addressArr = res.data.addresses

              this.addressLoading = false
            })
            .catch((err) => {
              this.addressLoading = false
            })
        })
        .catch((err) => {
          this.addressLoading = false
        })
      console.log(val, 'value')
    },
    // 新增地址
    addAddresses(index) {
      this.addressSelector.show = true
      // this.addressSelector.index = index
      // if (index === -1) {
      //   this.addressSelector.form = {
      //     lat: '',
      //     lng: '',
      //     address: '',
      //     address_name: '',
      //   }
      // } else {
      //   this.addressSelector.form = {
      //     lat: this.form.addresses[index].lat,
      //     lng: this.form.addresses[index].lng,
      //     address: this.form.addresses[index].address,
      //     address_name: this.form.addresses[index].name,
      //   }
      // }
    },
    // 选择所属企业
    changeCompanyId(id) {
      getCompanyDetail({ id }).then((res) => {
        console.log(res)

        this.addressArr = res.data.addresses
        this.form.address_id = ''
        this.form.company_category = res.data.category
      })
    },
    changeAddress(val) {
      console.log(val, 'val')
      this.form.address_id = val
      // getCompanyDetail({ id }).then((res) => {
      //   console.log(res)
      //   this.form.company_category = res.data.category
      // })
    },
    getAddressJson() {
      getAddressJson().then((res) => {
        this.areaData = res.data
      })
    },
    /**
     * 设置导入成功的文章内容
     * 设置封面图和标题
     * @param {Object} e
     */
    getImportDetail(e) {
      this.$set(this.form, 'details', e.content)
      this.$refs.editor.$emit('change', e.content)
    },
    cancel() {
      this.$router.back()
    },
    // 添加标签
    addTag(tag) {
      saveTag({
        name: tag,
      }).then((res) => {
        this.$message.success('添加成功')
        this.form.tags.push({
          name: tag,
          id: res.data.id,
        })
      })
    },
    // 保存配置
    saveConfig() {
      // 保存商城数据
      this.$refs.saveForm.validate((valid) => {
        if (valid) {
          this.loading = true
          const form = {
            ...this.form,
            close_time: (this.form.close_time / 1000).toFixed(0),
          }

          saveCareer(form)
            .then((res) => {
              this.$message.success(res.msg)
              this.loading = false
              this.cancel()
            })
            .catch((err) => {
              this.loading = false
            })
        } else {
          setTimeout(() => {
            this.$nextTick(() => {
              let isError = document.getElementsByClassName('is-error')
              console.log('isError', isError)
              isError[0].scrollIntoView({ block: 'center' })
            })
          }, 100)
          this.$message.error('请填写完整信息')
          return false
        }
      })
    },
    // 获取详情
    getDatail() {
      this.loading = true
      getCareerDetail({
        id: this.$route.params.id,
      })
        .then((res) => {
          this.form = res.data
          this.form.close_time = this.form.close_time * 1000
          getCompanyDetail({ id: this.form.company_id })
            .then((res) => {
              this.addressArr = res.data.addresses
              this.loading = false
            })
            .catch((err) => {
              this.loading = false
            })
        })
        .catch((err) => {
          this.loading = false
        })
    },
    // 获取全部企业
    getCompanyAll() {
      getCompanyAll()
        .then((res) => {
          this.companyArr = res.data.list
        })
        .catch((err) => {})
    },
    // // 获取岗位行业
    // getCategories() {
    //   getCategories().then((res) => {
    //     this.companyCategoryArr = res.data
    //   })
    // },
    // 获取岗位类型
    categoryList() {
      categoryList().then((res) => {
        this.categoryIdArr = res.data.list
      })
    },
    // 获取所属分类
    careerTypes() {
      careerTypes().then((res) => {
        this.careerTypesArr = res.data.list
      })
    },
  },
  created() {
    if (this.$route.params.id) {
      this.getDatail()
    }
    this.getCompanyAll()
    // this.getCategories()
    this.categoryList()
    this.getAddressJson()
    this.careerTypes()
  },
  computed: {
    // 薪资设置
    wagesRadio: {
      get() {
        if (this.form.min_wages == 0 && this.form.max_wages == 0) {
          return 0
        } else {
          return 1
        }
      },
      set(val) {
        console.log(val, 'val')
        if (val) {
          this.form.min_wages = 1
          this.form.max_wages = 2
        } else {
          this.form.min_wages = 0
          this.form.max_wages = 0
        }
      },
    },
    // 薪资设置
    expRadio: {
      get() {
        if (this.form.min_exp == 0 && this.form.max_exp == 0) {
          return 0
        } else {
          return 1
        }
      },
      set(val) {
        console.log(val, 'val')
        if (val) {
          this.form.min_exp = 1
          this.form.max_exp = 2
        } else {
          this.form.min_exp = 0
          this.form.max_exp = 0
        }
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.title {
  padding-left: 8px;
  font-size: 16px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1;
  border-left: 3px solid #3479ef;
  margin-bottom: 32px;
}
.form-tip {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #606266;
  line-height: 12px;
  margin-top: 15px;
  span + span {
    margin-left: 4px;
  }
}
.save-content {
  ::v-deep.el-input,
  ::v-deep.el-textarea {
    width: 300px;
  }
  .el-input-number {
    ::v-deep.el-input {
      width: auto;
    }
  }
}

::v-deep .category {
  .el-input__inner {
    border: none;
  }

  .el-input__suffix {
    display: none;
  }

  .el-cascader__tags {
    margin-left: -6px;
  }
}
</style>

<style lang="scss">
.popper-class {
  display: none;
}

.employ-types-popper {
  .el-cascader-menu__wrap {
    height: fit-content;
    max-height: 50vh;
  }
}
</style>
